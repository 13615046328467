'use client';

import Image from 'next/image';
import React from 'react';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

interface BaseVideoProps {
  videoId: string;
  title: string;
  containerClassName?: string;
  aspectRatio?: 'video' | 'square' | '16/9' | '4/3';
  poster?: 'hqdefault' | 'maxresdefault' | 'sddefault' | 'mqdefault';
  onIframeAdded?: () => void;
  noCookie?: boolean;
  uploadDate?: string;
  thumbnailOnly?: boolean;
  onClick?: () => void;
  playButtonStyle?: React.CSSProperties;
  playButtonBorderStyle?: React.CSSProperties;
  params?: string;
}

const BaseVideo = ({
  videoId,
  title,
  containerClassName = '',
  aspectRatio = 'video',
  poster = 'maxresdefault',
  onIframeAdded,
  noCookie = true,
  uploadDate = '2024-07-15T00:00:00+00:00',
  thumbnailOnly = false,
  onClick,
  playButtonStyle,
  playButtonBorderStyle,
  params = 'modestbranding=1&rel=0',
}: BaseVideoProps) => {
  const aspectRatioClasses = {
    video: 'aspect-video',
    square: 'aspect-square',
    '16/9': 'aspect-[16/9]',
    '4/3': 'aspect-[4/3]',
  };

  if (thumbnailOnly) {
    return (
      <div className={containerClassName}>
        <div
          className={`group relative ${
            aspectRatio === 'video' ? 'aspect-video' : ''
          } cursor-pointer`}
        >
          <Image
            src={`https://img.youtube.com/vi/${videoId}/${poster}.jpg`}
            alt={title}
            className="h-full w-full rounded-lg object-cover"
            width={1280}
            height={720}
          />
          <button
            type="button"
            aria-label={`Play video ${title}`}
            className="absolute inset-0 flex items-center justify-center"
            onClick={onClick}
          >
            <div
              className="flex h-14 w-20 items-center justify-center rounded-lg bg-red-600/80 transition-all group-hover:bg-red-600"
              style={{
                ...playButtonStyle,
                height: playButtonStyle?.height || '56px',
                width: playButtonStyle?.width || '80px',
              }}
            >
              <div
                className="h-0 w-0 border-y-[10px] border-l-[18px] border-y-transparent border-l-white"
                style={{
                  borderTopWidth:
                    playButtonBorderStyle?.borderTopWidth || '4px',
                  borderBottomWidth:
                    playButtonBorderStyle?.borderBottomWidth || '4px',
                  borderLeftWidth:
                    playButtonBorderStyle?.borderLeftWidth || '6px',
                }}
              />
            </div>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`relative ${aspectRatioClasses[aspectRatio]} ${containerClassName}`}
      itemScope
      itemType="https://schema.org/VideoObject"
    >
      <meta itemProp="name" content={title} />
      <meta itemProp="uploadDate" content={uploadDate} />
      <meta
        itemProp="embedUrl"
        content={`https://www.youtube.com/embed/${videoId}`}
      />
      <meta
        itemProp="thumbnailUrl"
        content={`https://i.ytimg.com/vi/${videoId}/${poster}.jpg`}
      />

      <LiteYouTubeEmbed
        id={videoId}
        title={title}
        poster={poster}
        webp // 使用 WebP 格式提升效能
        noCookie={noCookie} // GDPR 合規
        params={params} // 自訂 YouTube 參數
        onIframeAdded={onIframeAdded}
        adNetwork={false} // 禁用廣告以提升效能
        muted
      />

      {/* 無障礙支援 */}
      <a
        href={`https://www.youtube.com/watch?v=${videoId}`}
        className="sr-only focus:not-sr-only focus:absolute focus:z-10 focus:bg-white focus:p-2 focus:text-black"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={`Watch on YouTube ${title}`}
      >
        Watch on YouTube {title}
      </a>
    </div>
  );
};

export default BaseVideo;
